<template>
  <!-- 人才-实名认证 -->
  <div class="talentBg">
    <!-- 遮罩 -->
    <vue-element-loading
      :active="loadingActive"
      :is-full-screen="true"
      color="#2674fc"
      background-color="rgba(0,0,0,.3)"
      text="正在上传，请稍后！"
    />
    <section>
      <el-row class="talentIndex" :gutter="16">
        <el-col :span="8">
          <talentInfo></talentInfo>
        </el-col>
        <el-col :span="16">
          <div class="editPhoneBox">
            <!-- 重置密码 -->
            <div class="formBox" v-if="forgetBox">
              <el-form :model="realInfo" label-width="75px">
                <div v-show="hasRealData">
                  <el-form-item label="真实姓名" prop="name">
                    <el-input
                      placeholder="请输入真实姓名"
                      v-model="realInfo.name"
                      :disabled="hasRealData"
                    >
                      <i slot="prefix" class="el-input__icon el-icon-user"></i>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="性别" prop="u_sex">
                    <el-radio-group
                      v-model="realInfo.sex"
                      :disabled="hasRealData"
                    >
                      <el-radio label="1">男</el-radio>
                      <el-radio label="2">女</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="身份证号" prop="u_card_number">
                    <el-input
                      placeholder="请输入真实身份证号码"
                      v-model.number="realInfo.u_card_number"
                      autocomplete="off"
                      :disabled="hasRealData"
                    >
                      <i
                        slot="prefix"
                        class="el-input__icon el-icon-postcard"
                      ></i>
                    </el-input>
                  </el-form-item>
                </div>
                <el-form-item label="上传证件">
                  <el-row v-show="!hasRealData">
                    <el-col :span="12">
                      <el-upload
                        class="avatar-uploader"
                        :action="uploadSrc"
                        :data="fileUpType"
                        :headers="fileHeaders"
                        :auto-upload="true"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload"
                      >
                        <i v-if="!cardUrl1" class="el-icon-upload"></i>
                        <div v-if="!cardUrl1" class="el-upload__text">
                          添加身份证人身像
                        </div>
                        <img v-if="cardUrl1" :src="cardUrl1" class="cardImg" />
                      </el-upload>
                    </el-col>
                    <el-col :span="12">
                      <el-upload
                        class="avatar-uploader"
                        :action="uploadSrc"
                        :data="fileUpType"
                        :headers="fileHeaders"
                        :auto-upload="true"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess2"
                        :before-upload="beforeAvatarUpload2"
                      >
                        <i v-if="!cardUrl2" class="el-icon-upload"></i>
                        <div v-if="!cardUrl2" class="el-upload__text">
                          添加身份证国徽像
                        </div>
                        <img v-if="cardUrl2" :src="cardUrl2" class="cardImg" />
                      </el-upload>
                    </el-col>
                    <el-col
                      :span="24"
                      class="font-red"
                      style="line-height: 1.4;padding-top:5px"
                    >
                      注意：上传身份证人像面，并请确保身份证边框完整，字体清晰，亮度均匀，招聘仅支持jpg、jpeg、png格式，且大小在1M内
                    </el-col>
                  </el-row>
                  <span class="font-green" v-show="hasRealData">已上传</span>
                  <el-dialog
                    title="查看"
                    :close-on-click-modal="false"
                    :visible.sync="dialogVisible"
                  >
                    <img width="100%" :src="dialogImageUrl" alt="" />
                  </el-dialog>
                </el-form-item>
                <el-button
                  type="primary"
                  class="loginBtn"
                  :disabled="hasRealData"
                  v-prevent-repeat-click
                  @click="realPost"
                >
                  提交
                </el-button>
              </el-form>
            </div>
            <!-- 修改成功 -->
            <!-- <div class="formBox" v-if="editSuccessBox">
              <div class="successBox">
                <img :src="editSuccess" alt="" />
                <p>
                  手机号修改成功！请牢记，
                  {{ successSeconds }}
                  s后将返回登录页........
                </p>
                <el-button type="primary" class="loginBtn" @click="goLogin">
                  立即返回
                </el-button>
              </div>
            </div> -->
          </div>
        </el-col>
      </el-row>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";
import VueElementLoading from "vue-element-loading";
import talentInfo from "@/view/talentPage/talentInfo.vue";
export default {
  data() {
    return {
      editSuccess: require("@/assets/img/success.png"),
      smsTime: 60,
      successSeconds: 5,
      realInfo: {},
      forgetBox: true,
      editSuccessBox: false,
      dialogVisible: false,
      disabled: false,
      loadingActive: false,
      hasRealData: false,
      dialogImageUrl: "",
      fileList: [],
      cardUrl1: "",
      cardUrl2: "",
      cardimgId1: "",
      cardimgId2: "",
      // fileUpType:{}
    };
  },
  components: {
    talentInfo,
    VueElementLoading,
  },
  computed: {
    ...mapState(["userInfo", "uploadSrc", "talentInfo"]),
    fileUpType() {
      return {
        fi_objid: this.userInfo.uid,
        fi_objcat: "at",
        act: "upload",
      };
    },
    fileHeaders() {
      return {
        token: this.userInfo.token,
      };
    },
  },
  methods: {
    handleChange(e, m) {
      console.log(e);
      console.log(m);
    },
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 上传成功1
    handleAvatarSuccess(res, file) {
      if (res.resultCode === 0) {
        this.$message.warning("图片上传失败！");
        return;
      }
      this.cardUrl1 = URL.createObjectURL(file.raw);
      this.cardimgId1 = res.resultData.file_id;
      this.$http
        .get(
          "index/file_upload/upfile?fi_objid=5&fi_objcat=at&act=download&fi_id=" +
            this.cardimgId1
        )
        .then((res) => {
          if (res.data.resultCode === 1) {
            let faceurl = res.data.resultData.url;
            this.$http
              .post("talent/user/idcard", { url: faceurl, side: "front" })
              .then((res) => {
                this.loadingActive = false;
                if (res.data.resultCode === 1) {
                  this.realInfo.name = res.data.resultData.name;
                  this.realInfo.u_card_number = res.data.resultData.number;
                  if (res.data.resultData.sex == "男") {
                    this.realInfo.sex = 1;
                  } else if (res.data.resultData.sex == "女") {
                    this.realInfo.sex = 2;
                  }
                  this.$message.success("身份证正面上传成功！");
                }
              });
          }
        });
    },
    // 开始上传1
    beforeAvatarUpload(file) {
      const isJP = file.type === "image/jpeg" || file.type === "image/png";
      const isLt3M = file.size / 1024 / 1024 < 3;
      this.loadingActive = true;
      if (!isJP) {
        this.$message.error("上传图片只能是 JPG 格式或 PNG 格式!");
        this.loadingActive = false;
      }
      if (!isLt3M) {
        this.$message.error("上传图片最大不能超过 3MB!");
        this.loadingActive = false;
      }
    },
    // 上传成功2
    handleAvatarSuccess2(res, file) {
      if (res.resultCode === 0) {
        this.$message.warning("图片上传失败！");
        return;
      }
      this.cardUrl2 = URL.createObjectURL(file.raw);
      this.cardimgId2 = res.resultData.file_id;
      this.$http
        .get(
          "index/file_upload/upfile?fi_objid=5&fi_objcat=at&act=download&fi_id=" +
            this.cardimgId2
        )
        .then((res) => {
          if (res.data.resultCode === 1) {
            let backurl = res.data.resultData.url;
            this.$http
              .post("talent/user/idcard", { url: backurl, side: "back" })
              .then((res) => {
                this.loadingActive = false;
                if (res.data.resultCode === 1) {
                  this.$message.success("身份证反面上传成功！");
                }
              });
          }
        });
    },
    // 开始上传2
    beforeAvatarUpload2(file) {
      const isJP = file.type === "image/jpeg" || file.type === "image/png";
      const isLt3M = file.size / 1024 / 1024 < 3;
      this.loadingActive = true;
      if (!isJP) {
        this.$message.error("上传图片只能是 JPG 格式或 PNG 格式!");
        this.loadingActive = false;
      }
      if (!isLt3M) {
        this.$message.error("上传图片最大不能超过 3MB!");
        this.loadingActive = false;
      }
    },
    realPost() {
      if (this.realInfo.name == "") {
        this.$message.warning("真实姓名不能为空！");
        return;
      }
      if (this.realInfo.sex == "") {
        this.$message.warning("性别不能为空！");
        return;
      }
      if (this.realInfo.u_card_number == "") {
        this.$message.warning("身份证号码不能为空！");
        return;
      }
      if (this.realInfo.u_front_card == "") {
        this.$message.warning("身份证正面图片不能为空！");
        return;
      }
      if (this.realInfo.u_back_card == "") {
        this.$message.warning("身份证反面图片不能为空！");
        return;
      }
      this.realInfo.u_front_card = this.cardimgId1;
      this.realInfo.u_back_card = this.cardimgId2;
      this.$http
        .post("talent/user/certification", this.realInfo)
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.$message.success("实名认证提交成功，2秒后跳转个人首页！");
            setTimeout(() => {
              this.$router.push("/talentPage/index");
            }, 1300);
          }
        });
    },
    //
    setTalInfo() {
      if (Object.keys(this.talentInfo).length > 0) {
        if (this.talentInfo.u_card_number) {
          this.hasRealData = true;
          this.realInfo.u_card_number = this.talentInfo.u_card_number;
        }
      }
      this.$http.post("talent/index/baseinfo").then((res) => {
        if (res.data.resultCode === 1) {
          console.log(res.data.resultData.u_info.u_sex);
          console.log(res.data.resultData.u_info.u_name);
          // this.realInfo.sex = res.data.resultData.u_info.u_sex;
          // this.realInfo.name = res.data.resultData.u_info.u_name;
          this.$set(
            this.realInfo,
            "sex",
            res.data.resultData.u_info.u_sex.toString()
          );
          this.$set(this.realInfo, "name", res.data.resultData.u_info.u_name);
        }
      });
    },
  },
  mounted() {
    this.setTalInfo();
  },
};
</script>
<style scoped>
.talentIndex {
  padding-top: 24px;
}
.editPhoneBox {
  box-shadow: 0px 2px 12px 2px #d0d0d0;
  border-radius: 4px;
  padding: 40px 20px;
  margin-top: 2px;
}
.formBox {
  width: 500px;
  margin: 0 auto;
}
.loginBtn {
  width: 55%;
  display: block;
  margin: 36px auto 20px;
  border-radius: 32px;
  font-size: 16px;
}
.successBox {
  text-align: center;
}
.successBox p {
  color: #2573f1;
}
.avatar-uploader {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 200px;
  height: 125px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .cardImg {
  width: 100%;
}
.avatar-uploader .el-icon-upload {
  font-size: 67px;
  color: #c0c4cc;
  margin: 20px 0 0px;
  line-height: 50px;
}
.avatar-uploader .el-upload__text {
  color: #606266;
  font-size: 14px;
  text-align: center;
  line-height: 1.5;
}
.avatar-uploader:hover {
  border-color: #2573f1;
}
.avatar-uploader:hover .el-icon-upload,
.avatar-uploader:hover .el-upload__text {
  color: #2573f1;
}
</style>
